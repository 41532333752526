// Получение элементов
const participateBtns = document.querySelectorAll('.participate__btn');
const modalMenu = document.getElementById('modalMenu');
const closeModal = document.getElementById('closeModal');

// Получаем дополнительные модальные окна
const btcModal = document.getElementById('btcModal');
const ethModal = document.getElementById('ethModal');
const solModal = document.getElementById('solModal');

// Открытие основного меню при нажатии на любую кнопку
participateBtns.forEach(btn => {
    btn.addEventListener('click', () => {
        modalMenu.classList.add('active'); // Добавляем класс active
        modalMenu.style.display = 'flex'; // Отображаем меню
    });
});

// Закрытие основного меню при нажатии на кнопку "Close" или крестик
closeModal.addEventListener('click', () => {
    modalMenu.classList.remove('active'); // Убираем класс active
    modalMenu.style.display = 'none'; // Скрываем меню
});

// Закрытие основного меню при клике вне области содержимого
modalMenu.addEventListener('click', (e) => {
    if (e.target === modalMenu) {
        modalMenu.classList.remove('active'); // Убираем класс active
        modalMenu.style.display = 'none'; // Скрываем меню
    }
});

// Переход на соответствующее модальное окно в зависимости от выбора
document.getElementById('btcOption').addEventListener('click', () => {
    modalMenu.classList.remove('active');
    modalMenu.style.display = 'none';
    btcModal.style.display = 'flex';
});

document.getElementById('ethOption').addEventListener('click', () => {
    modalMenu.classList.remove('active');
    modalMenu.style.display = 'none';
    ethModal.style.display = 'flex';
});

document.getElementById('solOption').addEventListener('click', () => {
    modalMenu.classList.remove('active');
    modalMenu.style.display = 'none';
    solModal.style.display = 'flex';
});

// Закрытие дополнительных модальных окон
document.getElementById('closeBitcoinModal').addEventListener('click', () => {
    btcModal.style.display = 'none';
});

document.getElementById('closeEthModal').addEventListener('click', () => {
    ethModal.style.display = 'none';
});

document.getElementById('closeSolModal').addEventListener('click', () => {
    solModal.style.display = 'none';
});

// Закрытие дополнительных модальных окон при клике вне области содержимого
btcModal.addEventListener('click', (e) => {
    if (e.target === btcModal) {
        btcModal.style.display = 'none';
    }
});

ethModal.addEventListener('click', (e) => {
    if (e.target === ethModal) {
        ethModal.style.display = 'none';
    }
});

solModal.addEventListener('click', (e) => {
    if (e.target === solModal) {
        solModal.style.display = 'none';
    }
});
